import { SnackbarProvider } from "notistack";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store/Store';

const AppLazy = React.lazy(() =>
    import('components/App').then(module => ({
        default: module.App
    }))
);  

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <Suspense fallback=''>
                <AppLazy />
            </Suspense>
        </SnackbarProvider>
    </Provider>
    , document.getElementById('root')
);
