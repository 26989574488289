const GET_STATUS_SYNC_ALL = 'GET_STATUS_SYNC_ALL';
const UPDATE_FAILED_MESSAGE = 'UPDATE_FAILED_MESSAGE';
const UPDATE_SUCCESS_MESSAGE = 'UPDATE_SUCCESS_MESSAGE';
const CHANGE_SYNC_FACE_STATUS = 'CHANGE_SYNC_FACE_STATUS';
const initState = {
    isSyncAll: false,
    isSyncFace:false,
    failedMessage:'',
    successMessage:''
}

const SyncAllReducer = (state = initState,action)=>{
    switch (action.type) {
        case GET_STATUS_SYNC_ALL:
            return {...state,isSyncAll:!state.isSyncAll}
        case CHANGE_SYNC_FACE_STATUS:
            return {...state,isSyncFace:!state.isSyncFace}
        case UPDATE_FAILED_MESSAGE:
            return {...state,failedMessage:action.payload}
        case UPDATE_SUCCESS_MESSAGE:
            return {...state,successMessage:action.payload}
        default:
            return state;
    }
}

export default SyncAllReducer;