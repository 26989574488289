import { combineReducers } from 'redux';
import AdminUserReducer from './adminReducer/AdminUserReducer';
import cabinetReducer from './cabinetReducer';
import ConfigReducer from './ConfigReducer';
import NotificationReducer from './NotificationReducer';
import serviceBooking from './serviceBooking';
import LoginSessionReducer from './SessionReducer';
import SyncAllReducer from './SyncAllReducer';
import toastNotificationReducer from './toastNotification';
import utilityReducer from './utilityReducer';

const rootReducer = combineReducers({
    configState: ConfigReducer,
    notiState: NotificationReducer,
    sessionState: LoginSessionReducer,
    syncAllState: SyncAllReducer,
    adminUserState: AdminUserReducer,
    serviceBooking,
    cabinetReducer,
    toastNotification: toastNotificationReducer,
    utility: utilityReducer
})

export default rootReducer;