import { IToastNotificationProps } from "components/alert/Toast";

export type IToastNotificationReducer = Omit<IToastNotificationProps, 'onCloseToast'>
    & { isRefresh?: boolean };

export enum ToastNotificationActions {
    CHANGE_TOAST = 'CHANGE_TOAST',
    REFRESH_CURRENT_PAGE = 'REFRESH_CURRENT_PAGE',
}

const initState: IToastNotificationReducer = {
    type: "success",
    message: "",
    ms: 5000,
    vertical: "bottom",
    horizontal: "right",
    isRefresh: undefined,
}

const toastNotificationReducer = (state = initState, action: any) => {
    switch (action.type) {
        case ToastNotificationActions.CHANGE_TOAST:
            return {
                ...state,
                ...action.payload,
            }
        case ToastNotificationActions.REFRESH_CURRENT_PAGE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }

}

export default toastNotificationReducer;