export const PaymentConfiguration = {
    HIDE: -1,
    NOT_REQURIRE: 0,
    REQUIRE: 1,
    SHOW: 2,
    UNPAID: "unpaid",
    PAID: "paid",
    EXPIRED: "expired",
    ALLOW: 3,
    NEED_APPROVAL: 4,
    DENIED: 5
}

export const HandbookConfiguration = {
    SAVED: 0,
    PUBLISHED: 1,
    RETRIEVED: -1,
}

export const NotificationConfiguration = {
    ON: 1,
    OFF: -1,
    ASK: 0
}

export const FaceError = {
    IMAGE_IS_NONE: 477,
    INVALID_IMAGE: 480,
    CANNOT_DETECT_FACE: 481,
    BLURRY_IMAGE: 482,
    DUPLICATED: 483,
    MORE_THAN_ONE_FACE_IN_FRAME: 484,
    NO_FACE_LARGE_ENOUGH: 486,
    INVALID_FACE: 487,
    HOST_EXISTS: 'host exists',
    CAN_NOT_FIND_OUT_MAIN_RESIDENT: 'Can not find out Main Residence',
    EMAIL_EXISTS: 'Email exists',
    USERNAME_OR_PHONE_EXISTS: 'Username or Phone exists'
}

export const DefaultTimeZone = {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas"
    ]
}

export const USERNAME_CONFIGURATION = 'tsmart-building';

export const CallConfig = {
    ALL_RESIDENTS: 1,
    HOST_FIRST: 0,
    ONLY_HOST: -1
}

export const Filename5MB = 5242880;

export const IMAGE_PREFIX_BASE64 = 'data:image/png;base64, ';

export const AppointmentStatus = {
    EXPIRED: 'expired',
    PENDING: 'pending',
    CANCELED: 'canceled',
    REJECTED: 'rejected',
    APPROVED: 'approved'
}

export const UtilityRoomStatus = {
    WORKING: 'working',
    SUSPEND: 'suspend'
}

export const AirDitionerBranches = [
    'Samsung',
    'Panasonic',
    'Daikin',
    'Electrolux',
    'LG',
    'Toshiba',
    'Sharp',
    'Aqua',
    'Beko',
    'Gree',
    'Casper',
    'Midea',
    'TCL',
    'Kangaroo',
    'Comfee'
]

export const DeviceType = {
    SENSOR: 'Sensor',
    CONTROLLER: 'Controller',
    F10: 'F10',
    TOMMI_ACCESS: 'Tommi_Access',
    TOMMI_ELEVATOR: 'Tommi_Elevator',
    TLOCKER: 'Tlocker',
    GATEWAY: 'Gateway'
}

export const DevicePolicyType = {
    ROOM: 'roomPolicy',
    SERVICE: 'servicePolicy',
    AREA: 'areaPolicy',
    ELEVATOR: 'elevatorPolicy',
    NONE: 'none'
}

export const AssetUnit = ['m', 'm2', 'm3', 'lít', 'cái']


export const AssetRequestType = {
    IMPORT: 'import',
    EXPORT: 'export'
}

export const AssetBillStatus = {
    DRAFT: 'draft',
    DONE: 'done',
    EDITED: 'edited'
}

export const AdminRole = {
    ADMIN: 'Admin',
    ROOT: 'root',
    MODERATOR: 'moderator'
}

export const DefaultRole = {
    ADMIN: 'Admin',
    RECEPTIONIST: 'Receptionist',
    TECHNICAL_STAFF: 'Technical Staff'
}

export const STAFFACCOUNTSTATUS = {
    INACTIVE: "inactive",
    ACTIVE: "active",
    DEACTIVATED: "deactivated",
    DELETED: "deleted",
    RESTORE: "restore"
}

export const AccountPermissions = Object.freeze({
    TA_SIGN_IN: "TA_SIGN_IN",
    RECEPTIONIST_SIGN_IN: "RECEPTIONIST_SIGN_IN",
    RECEPTIONIST_SIGN_OUT: "RECEPTIONIST_SIGN_OUT",
    RECEPTIONIST_PASSWORD_CHANGE: "RECEPTIONIST_PASSWORD_CHANGE",
    ACCOUNT_CREATION: "ACCOUNT_CREATION",
    SIGN_IN: "SIGN_IN",
    SIGN_OUT: "SIGN_OUT",
    PASSWORD_CHANGE: "PASSWORD_CHANGE",
    STATISTIC: "STATISTIC",
    RESIDENT_LIST: "RESIDENT_LIST",
    RESIDENT_CREATION: "RESIDENT_CREATION",
    RESIDENT_APPROVAL: "RESIDENT_APPROVAL",
    RESIDENT_DETAIL: "RESIDENT_DETAIL",
    RESIDENT_UPDATE: "RESIDENT_UPDATE",
    RESIDENT_ACTIVATION: "RESIDENT_ACTIVATION",
    RESIDENT_DELETION: "RESIDENT_DELETION",
    STAFF_LIST: "STAFF_LIST",
    STAFF_CREATION: "STAFF_CREATION",
    STAFF_DETAIL: "STAFF_DETAIL",
    STAFF_UPDATE: "STAFF_UPDATE",
    STAFF_ACTIVATION: "STAFF_ACTIVATION",
    STAFF_DELETION: "STAFF_DELETION",
    ACCESS_LIST: "ACCESS_LIST",
    ACCESS_EXPORT: "ACCESS_EXPORT",
    ACCESS_DELETION: "ACCESS_DELETION",
    ACCESS_FACES_VIEW: "ACCESS_FACES_VIEW",
    WARNING_LIST: "WARNING_LIST",
    WARNING_UPDATE: "WARNING_UPDATE",
    BULLETIN_LIST: "BULLETIN_LIST",
    BULLETIN_CREATION: "BULLETIN_CREATION",
    BULLETIN_DETAIL: "BULLETIN_DETAIL",
    BULLETIN_UPDATE: "BULLETIN_UPDATE",
    BULLETIN_DELETION: "BULLETIN_DELETION",
    PAYMENT_LIST: "PAYMENT_LIST",
    PAYMENT_IMPORT: "PAYMENT_IMPORT",
    PAYMENT_EXPORT: "PAYMENT_EXPORT",
    PAYMENT_DETAIL: "PAYMENT_DETAIL",
    PAYMENT_UPDATE: "PAYMENT_UPDATE",
    PAYMENT_DELETION: "PAYMENT_DELETION",
    DEVICE_LIST: "DEVICE_LIST",
    DEVICE_CREATION: "DEVICE_CREATION",
    DEVICE_DETAIL: "DEVICE_DETAIL",
    DEVICE_UPDATE: "DEVICE_UPDATE",
    DEVICE_DELETION: "DEVICE_DELETION",
    SERVICE_LIST: "SERVICE_LIST",
    SERVICE_CREATION: "SERVICE_CREATION",
    SERVICE_DETAIL: "SERVICE_DETAIL",
    SERVICE_UPDATE: "SERVICE_UPDATE",
    SERVICE_DELETION: "SERVICE_DELETION",
    BOOKING_LIST: "BOOKING_LIST",
    BOOKING_CREATION: "BOOKING_CREATION",
    BOOKING_DETAIL: "BOOKING_DETAIL",
    BOOKING_APPROVAL: "BOOKING_APPROVAL",
    REFLECTION_LIST: "REFLECTION_LIST",
    REFLECTION_DETAIL: "REFLECTION_DETAIL",
    REFLECTION_UPDATE: "REFLECTION_UPDATE",
    SYSTEM_CONFIGURATION: "SYSTEM_CONFIGURATION",
    SMART_CAMERA_LIST: "SMART_CAMERA_LIST",
    SMART_CAMERA_CREATION: "SMART_CAMERA_CREATION",
    SMART_CAMERA_UPDATE: "SMART_CAMERA_UPDATE",
    SMART_CAMERA_DELETION: "SMART_CAMERA_DELETION",
    SECURITY_CAMERA_LIST: "SECURITY_CAMERA_LIST",
    SECURITY_CAMERA_CREATION: "SECURITY_CAMERA_CREATION",
    SECURITY_CAMERA_UPDATE: "SECURITY_CAMERA_UPDATE",
    SECURITY_CAMERA_DELETION: "SECURITY_CAMERA_DELETION",
    CABINET_STATISTIC: "CABINET_STATISTIC",
    CABINET_LIST: "CABINET_LIST",
    CABINET_CREATION: "CABINET_CREATION",
    CABINET_DETAIL: "CABINET_DETAIL",
    CABINET_UPDATE: "CABINET_UPDATE",
    ENERGY_WATER: "ENERGY_WATER",
    INVESTOR: "INVESTOR"
})

export const AccountHistory = {
    DELETE: 'DELETE',
    RESTORE: 'RESTORE',
    ADD: 'ADD',
    APP: 'APP',
    WEB: 'WEP'
}

export const ResidentRole = {
    MEMBER: 'member',
    HOST: 'host'
}

export const SexResident = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other'
}

export const ElevatorStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    EXPIRED: 'expired',
    DISABLED: 'disabled',
    ENABLED: 'enabled'
}

export const GuestMethod = {
    FACEID: 'faceId',
    QR: 'qr',
    BADGEID: 'badgeId',
}

export const ResidentCareer = [
    {
        title: 'Nhân viên văn phòng',
        value: 'Office staff'
    },
    {
        title: 'Nhân viên bán lẻ',
        value: 'Retail staff'
    },
    {
        title: 'Giáo viên/Nhân viên giáo dục',
        value: 'Teacher/Education staff'
    },
    {
        title: 'Kỹ sư',
        value: 'Engineer'
    },
    {
        title: 'Nhân viên y tế',
        value: 'Healthcare staff'
    },
    {
        title: 'Công nhân',
        value: 'Worker'
    },
    {
        title: 'Nhà kinh doanh',
        value: 'Entrepreneur'
    },
    {
        title: 'Người nội trợ',
        value: 'Housewifely'
    },
    {
        title: 'Học sinh/Sinh viên',
        value: 'Pupil/Student'
    },
    {
        title: 'Người về hưu',
        value: 'Retiree'
    },
    {
        title: 'Khác',
        value: 'Others'
    },
]

export const WaterLockStatus = {
    STANDARD: 'standard',
    SUBSTANDARD: 'substandard'
}

export const WaterQualityStatus = {
    PASS: 'PASS',
    FAIL: 'FAIL'
}

export const WaterFeeType = {
    POPULATION: 'population',
    QUANTITY: 'quantity'
}

export const WaterTypes = ['cisternWater', 'poolWater', 'wasteWater'];
