import { ListNonBookingServicesRes } from "api/dashboard/model"

export const UTILITY_ACTION = {
  GET_LIST_SERVICES_SUCCESSFULLY: "GET_LIST_SERVICES_SUCCESSFULLY",
}

interface IUtilityReducer {
  listNonBookingServices: ListNonBookingServicesRes
}

const initState: IUtilityReducer = {
  listNonBookingServices: {}
}

const utilityReducer = (state = initState, action: any) => {
  switch (action.type) {
    case UTILITY_ACTION.GET_LIST_SERVICES_SUCCESSFULLY:
      return {
        ...state,
        listNonBookingServices: { ...action.payload },
      }
    default:
      return state;
  }
}

export default utilityReducer;
