const IS_LOGIN_AGAIN_TRUE = 'IS_LOGIN_AGAIN_TRUE';
const IS_LOGIN_AGAIN_FALSE = 'IS_LOGIN_AGAIN_FALSE';

const initState = {
    loginSession:false
}
const SessionReducer = (state = initState, action) =>{
    switch (action.type) {
        case IS_LOGIN_AGAIN_TRUE:
            return {...state,loginSession:true}
        case IS_LOGIN_AGAIN_FALSE:
            return {...state,loginSession:false}
        default:
            return state;
    }
}

export default SessionReducer;
